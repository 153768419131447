import { IconChevronRightLine } from "@daangn/react-monochrome-icon";
import React from "react";

import { Link } from "../remix-lib";
import * as css from "./ThumbnailCardListHeader.css";

type ThumbnailCardListHeaderProps = {
  title: string;
  moreLabel?: string;
  moreHref?: string;
  eventClass?: {
    title?: string;
    more?: string;
  };
};

export default function ThumbnailCardListHeader({
  title,
  moreLabel,
  moreHref,
  eventClass,
}: ThumbnailCardListHeaderProps) {
  return (
    <header className={css.header}>
      <div data-gtm={eventClass?.title} className={css.title}>
        {title}
      </div>

      {moreLabel && (
        <Link className={css.label} href={moreHref}>
          <span data-gtm={eventClass?.more} className={css.moreArea}>
            {moreLabel}
          </span>
          <span className={css.iconWrapper}>
            <IconChevronRightLine className={css.icon} size={16} />
          </span>
        </Link>
      )}
    </header>
  );
}
